export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  VIN?: string;
  dates?: any[];
  unitNum?: string;
  idleReductionTime: number;
  engineOnTime: number;
  CO2Savings: number;
  batteryVoltage: number;
  batteryTemp: number;
  batteryVoltsGraph?: (number | null)[];
  batteryTempGraph?: (number | null)[];
  pluggedIn?: Date[];
  generatorOnTimes?: Date[];
  engineOnTimePark: number;
  status: string;
  stateOfCharge?: number;
  stealthBatteryAmps?: number;
  totalLoad?: number;
  loadGraph?: (number | null)[];
  SOCGraph?: (number | null)[];
  chargingTime?: number;
  generatorReductionTime: number;
  generatorOnTime: number;
  generatorStatus?: boolean;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
